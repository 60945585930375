
  
  /* Spinning Animation */
  .animate-spin {
    animation: spin 0.6s linear infinite; /* Decreased duration to 0.6 seconds for a faster spin */
    transform-origin: center;
    transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  /* Animation: Fade Out while Spinning */
  .animate-spin:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.8);
    pointer-events: none;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }
  
  .animated-button.animate-spin:before {
    opacity: 1;
  }
  